import { useQuery } from '@tanstack/react-query';
import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import endpoints from '../../common/endpoints.ts';
import api from '../../common/api/index.ts';
import {
  FastspringUnlimitedUserItems,
  FastspringUserCountItems,
} from '../../common/enums.ts';
import { useNavigate } from 'react-router-dom';
import AddUserModal from './modals/AddUserModal.tsx';

interface IProps {
  setCanAddUser: Dispatch<SetStateAction<boolean>>;
}

const ManageUsers = ({ setCanAddUser }: IProps) => {
  const navigate = useNavigate();

  const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);
  const [maxUsers, setMaxUsers] = useState<number>(0);
  const [showInactiveUsers, setShowInactiveUsers] = useState<boolean>(false);

  const { data: user } = useQuery({
    queryKey: [endpoints.user.read.one.by.id()],
    queryFn: api.user.read.one.by.id,
  });

  const { data: subscriptions } = useQuery({
    queryKey: [endpoints.fastspring.read.all.subscriptions.by.userId(), user],
    queryFn: api.fastspring.read.all.subscriptions.by.userId,
  });

  const { data: userCount } = useQuery({
    enabled: Boolean(user?.customerId),
    queryKey: [
      endpoints.customer.read.activeUserCount.by.customerId(),
      user?.customerId,
    ],
    queryFn: api.customer.read.activeUserCount.by.customerId,
  });

  const { data: users } = useQuery({
    queryKey: [endpoints.user.read.all.by.customerId()],
    queryFn: () => api.user.read.all.by.customerId(),
  });

  const addUser = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setAddModalIsOpen(true);
  };

  useEffect(() => {
    if (!subscriptions) return;
    if (
      Object.values(FastspringUnlimitedUserItems).some(item =>
        subscriptions.map(sub => sub.product.toLocaleLowerCase()).includes(item)
      )
    )
      return setMaxUsers(Infinity);

    const innerMaxUsers = subscriptions.reduce(
      (acc, sub) =>
        acc +
        (Object.values(FastspringUserCountItems)
          .map(item => item.toString())
          .includes(sub.product.toLocaleLowerCase())
          ? sub.quantity
          : 0),
      0
    );

    return setMaxUsers(innerMaxUsers);
  }, [subscriptions, user, userCount]);

  useEffect(() => {
    setCanAddUser(maxUsers > (userCount || 0));
  }, [userCount, maxUsers]);

  const goToUser = (userId: number) => {
    navigate(`/users/${userId}`);
  };

  return (
    <div>
      <div className="row" />
      {(userCount || 0) < maxUsers && (
        <div className="row">
          <div className="col s12 m4 offset-m8">
            <a
              href="/"
              className="btn-small blue white-text waves-effect waves-light col s12"
              onClick={addUser}
            >
              Invite New User
            </a>
          </div>
        </div>
      )}
      <div className="row" style={{ marginBottom: '40px' }}>
        <span>Total Users: {userCount || 0}</span>
        <br />
        <span>
          Available Invites:{' '}
          {maxUsers - (userCount || 0) === Infinity
            ? 'Unlimited'
            : maxUsers - (userCount || 0)}
        </span>
      </div>

      <div className="row">
        <p className="right">
          <label>
            <input
              type="checkbox"
              className="filled-in"
              checked={showInactiveUsers}
              onClick={() => setShowInactiveUsers(prev => !prev)}
            />
            <span>Show Deactivated Users</span>
          </label>
        </p>
        <table className="highlight">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users
              ?.filter(({ isActive }) => (showInactiveUsers ? true : isActive))
              .map(user => {
                const {
                  id,
                  firstName,
                  middleName,
                  lastName,
                  userContacts,
                  isActive,
                } = user;

                return (
                  <tr
                    key={id}
                    style={{
                      cursor: 'pointer',
                      fontStyle: isActive ? 'inherit' : 'italic',
                    }}
                    className={isActive ? '' : 'grey-text'}
                    onClick={() => goToUser(id)}
                  >
                    <td style={{ padding: '4px' }}>
                      {firstName}{' '}
                      {middleName?.split('').shift()?.toLocaleUpperCase() || ''}{' '}
                      {lastName || ''}{' '}
                      {user.customer.adminId === user.id && (
                        <span style={{ fontStyle: 'italic' }}>(Me)</span>
                      )}
                    </td>
                    <td style={{ padding: '4px' }}>
                      {userContacts?.find(({ type }) => type === 'email')
                        ?.contactInfo || ''}
                    </td>
                    {!isActive && (
                      <td style={{ padding: '4px' }}>Deactivated</td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <AddUserModal
        isOpen={addModalIsOpen}
        setIsOpen={setAddModalIsOpen}
        customerId={user?.customerId || 0}
      />
    </div>
  );
};

export default ManageUsers;
